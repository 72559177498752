import React, {useRef, useState} from "react";
import Uploader from "./Uploader";
import "./styles.css";
import moment from 'moment';

import DetailLogo from "../../images/Detail-icon.svg";

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { DateTimePickerComponent  } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import DeleteDialog from "./Dialog/DeleteDialog";
import WarningDialog from "./Dialog/WarningDialog";
import ErrorDialog from "./Dialog/ErrorDialog";
import LoadingComponent from "../Loading/LoadingComponent";

import JobQueueTable from "./JobQueueTable"
import { MAX_CONCURRENT_UPLOAD, MAX_UPLOAD_SIZE_FORMATTED } from "../../limit";
import api from "../../lib/API";
import localStorageService from "../../lib/LocalStorageService";

export default function JobQueue(){
    const [uploadedTimeFrom, setUploadedTimeFrom] = useState('');
    const [uploadedTimeTo, setUploadedTimeTo] = useState('');
    const [uploadedBy, setUploadedBy] = useState(0);

    const gridRef = useRef(null);
   
    const AI_VERSION = (process.env.REACT_APP_AI_VERSION  || '1.0');

    function fetchJobs(){
        if (gridRef.current) {
            gridRef.current.refresh(uploadedTimeFrom, uploadedTimeTo, uploadedBy);
        }
    }

    const permission = localStorageService.getPermissions();

    return (
        <>
            <div className="content pb-0">
                <div className="heading mb-3">
                    <h4 className="page-title" style={{marginTop:"10px"}}>Job Queue</h4>
                </div>
                <JobQueueTable
                    ref={gridRef}
                />
            </div>
        </>
    );

}
