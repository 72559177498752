const LocalStorageService = (() => {
  let _service;

  const _getService = function () {
    if (!_service) {
      _service = this;
      return _service
    }
    return _service
  };

  let _pwdExpiryReminderVisibilityCallback = null;

  const _setToken = tokenObj => {
    if (tokenObj.access) localStorage.setItem('access_token', tokenObj.access);
    if (tokenObj.refresh) localStorage.setItem('refresh_token', tokenObj.refresh);
    if (tokenObj.username) localStorage.setItem('username', tokenObj.username);
    if (tokenObj.displayName) localStorage.setItem('display_name', tokenObj.displayName);
    if (tokenObj.permissions) localStorage.setItem('permissions', tokenObj.permissions);
    if (typeof(tokenObj.pwdExpiryReminder) !== 'undefined') {
      const pwdExpiryReminder = new Boolean(tokenObj.pwdExpiryReminder).toString();
      localStorage.setItem('pwd_expiry_reminder', pwdExpiryReminder);
      if (_pwdExpiryReminderVisibilityCallback) {
        _pwdExpiryReminderVisibilityCallback(pwdExpiryReminder === "true");
      }
    }
  };
  const _setPermissions = (permissions) => localStorage.setItem('permissions', permissions);
  const _setPrevPath = (pathname) => localStorage.setItem('prevPath', pathname);
  const _setProfileUser = (data) => localStorage.setItem('user_profile_data', data);
  const _setPwdExpiryReminder = (value) => {
    localStorage.setItem('pwd_expiry_reminder', value);
    if (_pwdExpiryReminderVisibilityCallback) {
      _pwdExpiryReminderVisibilityCallback(value === "true");
    }
  }

  const _getAccessToken = () => localStorage.getItem('access_token');
  const _getRefreshToken = () => localStorage.getItem('refresh_token');
  const _getUsername = () => localStorage.getItem('username');
  const _getDisplayName = () => localStorage.getItem('display_name');
  const _getPermissions = () => localStorage.getItem('permissions');
  const _getPrevPath = () => localStorage.getItem('prevPath');
  const _getProfileUser = (pathname) => localStorage.getItem('user_profile_data');
  const _getPwdExpiryReminder = () => localStorage.getItem('pwd_expiry_reminder');

  const _clearToken = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('username');
    localStorage.removeItem('display_name');
    localStorage.removeItem('permissions');
    localStorage.removeItem('pwd_expiry_reminder');
  };
  const _clearProfileUser = () => {
    localStorage.removeItem('user_profile_data');
  };
  const _clear = ()=>{
    localStorage.clear();
  };

  const _setPwdExpiryReminderVisibilityCallback = (func) => {
    _pwdExpiryReminderVisibilityCallback = func;
  };

  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
    getDisplayName: _getDisplayName,
    getUsername: _getUsername,
    setPrevPath: _setPrevPath,
    getPrevPath: _getPrevPath,
    clearAll: _clear,
    getPermissions:_getPermissions,
    setPermissions:_setPermissions,
    getProfileUser: _getProfileUser,
    setProfileUser: _setProfileUser,
    clearProfileUser: _clearProfileUser,
    setPwdExpiryReminder: _setPwdExpiryReminder,
    getPwdExpiryReminder: _getPwdExpiryReminder,
    setPwdExpiryReminderVisibilityCallback: _setPwdExpiryReminderVisibilityCallback,
  }
})();
export default LocalStorageService;
