import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { ProgressButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import OptixLogo from "../../images/Optix-logo-black.svg";
import HidePasswordIcon from "../../images/HidePassword-icon.svg";
import ShowPasswordIcon from "../../images/ShowPassword-icon.svg";
import api from '../../lib/API';
import "./resetPassword.css";

export default function ResetPassword() {
    const { token } = useParams();
    const history = useHistory();
    const buttonRef = useRef(null);

    const [username, setUsername] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [tokenValid, setTokenValid] = useState(false);
    const [password, setPassword] = useState('');
    const [retypePassword, setRetypePassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showRetypePassword, setShowRetypePassword] = useState(false);
    const [requestStatus, setRequestStatus] = useState(0); // 0 -> not sent, 1 -> success, -1 -> failed
    const [errorMessages, setErrorMessages] = useState([]);

    useEffect(() => {
        api.post('reset_password/validate', { passwordToken: token })
            .then(() => {
                setIsLoading(false);
                setTokenValid(true);
            })
            .catch(() => {
                setIsLoading(false);
                setTokenValid(false);
            });
    }, [token]);

    const onKeyDown = (e) => {
        if (e.keyCode === 13 && username) {
            onRequestPasswordReset();
            e.preventDefault();
        }
    };

    const onChangeData = (value, field) => {
        if (field === 'password') setPassword(value);
        if (field === 'retypePassword') setRetypePassword(value);
        setErrorMessages([]);
    };

    const onProgress = (args) => {
        if (!isLoading) args.percent = 100;
    };

    const onRequestPasswordReset = () => {
        setIsLoading(true);
        buttonRef.current.start();
        api.post('reset_password/confirm', { password: password, passwordToken: token })
            .then(() => {
                setIsLoading(false);
                setRequestStatus(1);
                setErrorMessages([]);
            })
            .catch((err) => {
                setIsLoading(false);
                const passwordError = err?.response?.data?.message;
                setErrorMessages([passwordError] || []);
                setRequestStatus(passwordError ? 0 : -1);
            });
    };

    return (
        <div className="reset-back">
            <div className="reset-container-wrapper">
                <div className="row h-100 justify-content-center align-items-center">
                    <div>
                        <div className="mb-4">
                            <img src={OptixLogo} style={{paddingRight: "0px"}} alt="Optix Logo" />
                        </div>
                        <div className="mb-4" style={{ width: "100%", textAlign: 'center' }}>
                            <span className="reset-login-title mt-4">
                                SDG Classification and Keyword Recomendation
                            </span>
                        </div>

                        {!tokenValid || requestStatus === -1 ? (
                            <div className="reset-form pb-5">
                                <span className="reset-login-text">
                                    The password reset link was invalid or expired. Please request a new password reset.
                                </span>
                            </div>
                        ) : null}

                        {tokenValid && requestStatus === 0 && (
                            <div className="reset-container-white-2"> {/* Changed from forgot-container-white to container-white-2 */}
                                <span className="reset-login-title">Reset Password</span>
                                <div className="mt-4" style={{ position: "relative" }}>
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        className="reset-resetform-input mt-4"
                                        placeholder="New Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <button
                                        type="button"
                                        onClick={() => setShowPassword(!showPassword)}
                                        style={{
                                            position: "absolute",
                                            right: "5px",
                                            top: "50%",
                                            transform: "translateY(-30%)",
                                            background: "none",
                                            outline: "none",
                                            border: "none",
                                            cursor: "pointer"
                                        }}
                                    >
                                        {showPassword ? <img src={ShowPasswordIcon} alt="Show Password"/> : <img src={HidePasswordIcon} alt="Hide Password"/>}
                                    </button>
                                </div>

                                <div className="mt-2" style={{ position: "relative" }}>
                                    <input
                                        type={showRetypePassword ? "text" : "password"}
                                        className="reset-resetform-input"
                                        placeholder="Re-type New Password"
                                        value={retypePassword}
                                        onChange={(e) => setRetypePassword(e.target.value)}
                                    />
                                    <button
                                        type="button"
                                        onClick={() => setShowRetypePassword(!showRetypePassword)}
                                        style={{
                                            position: "absolute",
                                            right: "5px",
                                            top: "50%",
                                            transform: "translateY(-70%)",
                                            background: "none",
                                            outline: "none",
                                            border: "none",
                                            cursor: "pointer"
                                        }}
                                    >
                                        {showRetypePassword ? <img src={ShowPasswordIcon} alt="Show Password"/> : <img src={HidePasswordIcon} alt="Hide Password"/>}
                                    </button>
                                </div>

                                <div className="reset-resetform-requirements mt-4 mb-4">
                                    <div className="reset-login-title"><b>Passwords must:</b></div>
                                    <div className="reset-login-text">Be a minimum of 8 characters</div>
                                    <div className="reset-login-text">Include at least one lowercase letter (a-z)</div>
                                    <div className="reset-login-text">Include at least one uppercase letter (A-Z)</div>
                                    <div className="reset-login-text">Include at least one number (0-9)</div>
                                    <div className="reset-login-text">Include at least one special character</div>
                                </div>

                                {errorMessages.map((msg, idx) => (
                                    <div key={idx} className="text-danger text-left mb-2">
                                        <span>{msg}</span>
                                    </div>
                                ))}

                                <ProgressButtonComponent
                                    cssClass="reset-btn-dark"
                                    ref={buttonRef}
                                    content="Confirm"
                                    style={{ opacity: !password || !retypePassword || password !== retypePassword ? 0.5 : 1 }}
                                    disabled={!password || !retypePassword || password !== retypePassword}
                                    onClick={onRequestPasswordReset}
                                    progress={onProgress}
                                />
                            </div>
                        )}

                        {tokenValid && requestStatus === 1 && (
                            <div className="reset-form pb-5">
                                <span className="reset-login-title mt-4">Password Reset</span>
                                <div className="mt-4 mb-4">
                                    <span className="reset-login-text">
                                        Your password has been successfully reset. Click below to login.
                                    </span>
                                </div>
                                <ButtonComponent
                                    cssClass="reset-btn-dark"
                                    onClick={() => history.push('/login')}
                                >
                                    Go To Login Page
                                </ButtonComponent>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
