import React, {useState, useEffect, useRef} from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import api from "../../../lib/API";
import localStorageService from "../../../lib/LocalStorageService";

export default function EditDialog (props) {
    const [role, setRole] = useState('');
    const [name, setName] = useState('');
    const [department, setDepartment] = useState('');
    const [email, setEmail] = useState('');
    const [rolesList, setRolesList] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isConfirmed, setIsConfirmed] = useState(false);

    const [roleIndex, setRoleIndex] = useState([]);

    const departmentRef = useRef(null);
    const emailRef = useRef(null);
    const nameRef = useRef(null);
    const roleRef = useRef(null);
    const confirmRef = useRef(null);

    useEffect(()=>{
        let mounted = true;
        if(mounted && props.visibility){
            if(departmentRef.current.cssClass === "e-error")
                departmentRef.current.cssClass = '';
        }
        return () => mounted = false;
    },[department])

    useEffect(()=>{
        let mounted = true;
        if(mounted && props.visibility){
            if(emailRef.current.cssClass === "e-error")
                emailRef.current.cssClass = '';
        }
        return () => mounted = false;
    },[email])

    useEffect(()=>{
        let mounted = true;
        if(mounted && props.visibility){
            if(nameRef.current.cssClass === "e-error")
                nameRef.current.cssClass = '';
        }
        return () => mounted = false;
    },[name])

    useEffect(()=>{
        let mounted = true;
        if(mounted && props.visibility){
            if(roleRef.current.cssClass === "e-error")
                roleRef.current.cssClass = '';
        }
        return () => mounted = false;
    },[role])

    const handleExit = () => {
        setIsConfirmed(false);
        props.setVisibility(false);
        setErrorMessage('');
        setName('');
        setRole('');
        setDepartment('');
        setEmail('');
        departmentRef.current.value = department;
        emailRef.current.value = email;
        nameRef.current.value = name;
        roleRef.current.index = roleIndex;
        departmentRef.current.cssClass = '';
        emailRef.current.cssClass = '';
        nameRef.current.cssClass = '';
        roleRef.current.cssClass = '';

    }

    const updateUserInfo = () => {
        
        const params = {
            roleId:role,
            displayName:name,
            department:department,
            email:email
        }
        console.log(params);
        api.put(`/users/${props.row.userId}`,params).then(async res => {
            console.log(res.data);
            handleExit();
            props.fetchUsers();
        }, err => {
            setErrorMessage(err.response.data.message);
            console.log(err);
            setIsConfirmed(false);
            if(err.response.data.errorFieldList.length !== 0)
                err.response.data.errorFieldList.forEach(element => {
                    switch (element) {
                        case "role":
                            roleRef.current.cssClass = "e-error";
                            break;
                        case "name":
                            nameRef.current.cssClass = "e-error";
                            break;
                        case "department":
                            departmentRef.current.cssClass = "e-error";
                            break;
                        case "email":
                            emailRef.current.cssClass = "e-error";
                            break;
                        default:
                            break;
                    }
                });
        })
    }

    useEffect(()=>{
        let mounted = true;
        if(mounted && props.visibility){
            if(!isConfirmed){
                confirmRef.current.removeAttribute("disabled");
                confirmRef.current.innerHTML = `<span>Confirm</span>`;
            }
            else{
                confirmRef.current.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="ml-2">Confirm</span>`;
                confirmRef.current.setAttribute("disabled","disabled");
            }
        }
        return () => mounted = false;
    },[isConfirmed])

    const footerTemplate = () => {
        return (
        <div className="row">
            <div className="col-7">
                {errorMessage && <p style={{textAlign:"left !important"}} className="float-left mt-2 text-left text-danger m-0">{errorMessage}</p>}
            </div>
            <div className="col-5">
                <button className="btn cancel mr-2 mb-2 pl-4 pr-4" 
                onClick={()=>handleExit()}>Cancel</button>
                <button
                ref={confirmRef}
                className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
                disabled={!name || !role || !department || !email || (name === props.row.displayName && department === props.row.department && email === props.row.email && role === props.row.roleId)}
                onClick={()=>{
                    setIsConfirmed(true);
                    updateUserInfo();
                }}
                >Confirm</button>
            </div>
        </div>)

    }

    return (
        <div className="App" id='dialog-target'>
                <DialogComponent
                    style={{ maxHeight: "none", minHeight: "480px"}}
                    width="40%"
                    close={()=>handleExit()}
                    header='Edit User'
                    visible={props.visibility}
                    showCloseIcon={false}
                    footerTemplate={footerTemplate}
                    isModal={true}
                    beforeOpen={()=>{
                        setRole(props.row.roleId);
                        setName(props.row.displayName);
                        setDepartment(props.row.department);
                        setEmail(props.row.email);
                        console.log(props.rolesList.roleList, props.row.roleName)
                        setRoleIndex(props.rolesList.roleList.findIndex(e=>{return e.roleName === props.row.roleName}))
                    }}
                    // ref={dialog => dialogInstance = dialog} 
                    overlayClick = {()=>handleExit()}
                >
                    <div className="p-2">
                        <div className="row">
                            <div className="col">
                                <p className="m-0 font-weight-bold">Username</p>
                                <span className="">{props.row.username}</span>
                            </div>
                            <div className="col">
                                <p className=" font-weight-bold m-0">Name</p>
                                <TextBoxComponent
                                ref={nameRef}
                                value={name}
                                placeholder="Name" 
                                floatLabelType="Never"
                                input={(e)=>setName(e.value)}
                                change={(e)=>setName(e.value)} />
                            </div>
                        </div>

                        <div className="mt-4">
                            <div className="row">
                                <div className="col">
                                    <p className=" font-weight-bold m-0">Department</p>
                                    <TextBoxComponent
                                    ref={departmentRef}
                                    value={department} 
                                    placeholder="Department" 
                                    floatLabelType="Never"
                                    input={(e)=>{
                                        setDepartment(e.value)}}
                                    change={(e)=>{
                                        setDepartment(e.value)}} />
                                </div>
                                <div className="col">
                                    <p className=" font-weight-bold m-0">Email</p>
                                    <TextBoxComponent
                                    ref={emailRef}
                                    value={email} 
                                    placeholder="Email" 
                                    floatLabelType="Never"
                                    input={(e)=>{
                                        setEmail(e.value)}}
                                    change={(e)=>{
                                        setEmail(e.value)}} />
                                </div>
                            </div>
                        </div>

                        <div className="mt-4">
                            <div className="row">
                                <div className="col">
                                    <p className="m-0 font-weight-bold">Role</p>
                                    {(props.row.username?.toLowerCase() !== localStorageService.getUsername().toLowerCase() ?
                                    <DropDownListComponent
                                    ref={roleRef}
                                    fields={{text:"roleName", value:"roleId"}}
                                    index={roleIndex}
                                    dataSource={props.rolesList?props.rolesList.roleList:null}
                                    change={element=>{
                                        setRole(element.itemData.roleId);
                                        console.log(element.itemData);
                                        // setRole(rolesList[rolesList.findIndex(e=>{
                                        //     console.log(e.roleName);
                                        //     console.log(element.itemData.roleName);
                                        //     return e.roleName === element.itemData.roleName})].roleId)
                                    }}
                                    /> : <span ref={roleRef} className="">{props.row.roleName}</span>)}
                                </div>
                                <div className="col">
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogComponent>
        </div>
            );

}