import React, {useState, useEffect, useRef} from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import {
    AccumulationChartComponent,
    AccumulationSeriesCollectionDirective,
    AccumulationSeriesDirective,
    Inject,
    AccumulationDataLabel,
} from '@syncfusion/ej2-react-charts';
import moment from "moment";
import api from "../../lib/API";
import CreateLogo from "../../images/Create.svg";
import AnalysisLogo from "../../images/Mainmenu-analysis.svg";
import ArrowPrevIcon from "../../images/arrow-left-back.svg";
import ArrowUpIcon from "../../images/arrow-up.svg";
import ArrowDownIcon from "../../images/arrow-down.svg";
import ArrowRightIcon from "../../images/arrow-right.svg";
import PrinterIcon from "../../images/printer.svg";
import localStorageService from "../../lib/LocalStorageService";
import "./style.css";
import AnalysisHistory from "./AnalysisHistory";

export default function Analysis (props) {
    const permission = localStorageService.getPermissions();
    const newAnalysisRef = useRef(null);
    const analyzeRef = useRef(null);
    const titleRef = useRef(null);
    const abstractRef = useRef(null);
    const authorKeywordsRef = useRef(null);
    const toast = useRef(null);

    const [historyList, setHistory] = useState(null);
    const [title, setTitle] = useState(null);
    const [abstract, setAbstract] = useState(null);
    const [authorKeywords, setAuthorKeywords] = useState(null);
    const [jobID, setJobID] = useState(-1);
    const [prevJobID, setPrevJobID] = useState(-1); // keep track whether New Analysis comes from "main" page or "results" page
    const [currentJob, setCurrentJob] = useState(null);
    const [analysisHistoryClicked, setAnalysisHistoryClicked] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [showAll, setShowAll] = useState(false);
    const sdgNames = [
        "No Poverty",
        "Zero Hunger",
        "Good Health and Well-being",
        "Quality Education",
        "Gender Equality",
        "Clean Water and Sanitation",
        "Affordable and Clean Energy",
        "Decent Work and Economic Growth",
        "Industry, Innovation, and Infrastructure",
        "Reduced Inequality",
        "Sustainable Cities and Communities",
        "Responsible Consumption and Production",
        "Climate Action",
        "Life Below Water",
        "Life on Land",
        "Peace, Justice, and Strong Institutions",
        "Partnerships for the Goals"
    ];

    useEffect(()=>{
        let mounted = true;
        if(mounted){
            api.get(`analysis/history`)
            .then(async (res) => {
                setHistory(res.data.historyList);
                if (historyList){
                    setPrevJobID(jobID);
                    setJobID(historyList[0].jobID);
                }
            }, err => {
                console.log(err.data);
                setPrevJobID(jobID);
                setHistory(null);
            });
            fetchUpdate();
            setAnalysisHistoryClicked(true);
        }
        return () => mounted = false;
    },[]);

    const fetchUpdate = () => {
        
    }

    const handleBackClick = () => {
        if (prevJobID < 0){
            setAnalysisHistoryClicked(true);
        }
        else{
            setJobID(prevJobID);
        }
    }

    const showToast = (message) => {
        setToastMessage(`${message} <a href="/job-queue" class="view-text">View</a>`);
        toast.current.show();
    };

    const updateTitle = (title) => {
        setTitle(title);
    }

    const updateAbstract = (abstract) => {
        setAbstract(abstract);
    }

    const updateAuthorKeywords = (authorKeywords) => {
        setAuthorKeywords(authorKeywords);
    }

    const handleClick = (id) => {
        setPrevJobID(jobID);
        setJobID(id);
        setShowAll(false);
    };

    const handleReset = () => {
        updateTitle("");
        updateAbstract("");
        updateAuthorKeywords("");
    };

    useEffect(() => {
        if (jobID == -1){
            setCurrentJob(null);
        }
        else{
            api.get(`analysis/${jobID}`)
            .then(async (res) => {
                setCurrentJob(res.data);
            }, err => {
                console.log(err.data);
                setCurrentJob(null);
            });
        }
    }, [jobID]);

    const HistorySession = () => {
        const recentHistoryList = historyList ? historyList.slice(0, 8) : [];
    
        return (
            <div className="history-panel no-print">
                <div style={{padding: "0px 10px"}}>
                    <button
                        ref={newAnalysisRef}
                        className="btn btn-dark"
                        style={{
                            paddingLeft: "100px",
                            paddingRight: "100px",
                            display: "block",
                            margin: "0 auto",
                            marginBottom: "20px"
                        }}
                        onClick={() => handleClick(-1)}
                    >
                        <img src={CreateLogo} alt="" />{` `} New Analysis
                    </button>
                    <p style={{fontWeight: "600"}}>History</p>
                </div>
                <div className="history-list">
                    {recentHistoryList.map((item, index) => (
                        <div
                            key={item.jobID}
                            style={{
                                margin: "0px -20px",
                                backgroundColor: (jobID && jobID === item.jobID) ? "#F5F5F5" : "#FFFFFF"
                            }}
                        >
                            <div
                                onClick={() => handleClick(item.jobID)}
                                style={{
                                    margin: "0px 20px",
                                    borderBottom: index === recentHistoryList.length - 1 ? "none" : "1px solid #D9D9D9",
                                    cursor: "pointer",
                                    padding: "10px"
                                }}
                            >   
                                <p style={{ marginBottom: "5px" }}>{item.title}</p>
                                <div style={{ display: "flex", alignItems: "center", color: "#9C9C9C", fontWeight: "400" }}>
                                    <span style={{ marginRight: "10px" }}>
                                        {(() => {
                                            const processedTime = moment(item.processedTime);
                                            const isLessThan24Hours = moment().diff(processedTime, 'hours') < 24;
                                            return isLessThan24Hours 
                                                ? processedTime.format("HH:mm")
                                                : processedTime.format("MMM D HH:mm");
                                        })()}
                                    </span>
                                    
                                    <div className="sdg-list-container">
                                        {item.keySDGs.split(',').map((sdg_number, idx) => (
                                            <div
                                                key={`history-${item.jobID}-${idx}`}
                                                className={`goal-icon goal-${sdg_number.trim()} sdg-list-box`}
                                            >
                                                {sdg_number.trim()}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <p
                    style={{
                        cursor: "pointer",
                        float: "right",
                        marginTop: "10px"
                    }}
                    onClick={() => {
                        setShowAll(false);
                        setAnalysisHistoryClicked(true);
                        setJobID(-1);
                        setPrevJobID(-1);
                    }}
                >
                    View All <img src={ArrowRightIcon} alt="Arrow Right" style={{ marginLeft: "5px" }} />
                </p>
            </div>
        );
    };

    const PublicationDetails = () => {
        return (
            <div className="container-white">
                <p style={{fontSize:"18px"}}>{currentJob.title}</p>
                <p style={{marginTop: "20px"}}>
                    <span>Job ID</span>
                    <span style={{ marginLeft: "15px", fontWeight: "400" }}>{currentJob.jobID}</span>
                    <span style={{ marginLeft: "100px" }}>Processed Time</span>
                    <span style={{ marginLeft: "15px", fontWeight: "400" }}>{moment(currentJob.processedTime).format("YYYY-MM-DD HH:mm")}</span>
                </p>
                <hr className="footer"/>
                <p style={{marginTop: "20px"}}>
                    <div>Abstract</div>
                    <div style={{ marginTop: "5px", fontWeight: "400" }}>{currentJob.abstract}</div>
                </p>
                <p style={{marginTop: "20px"}}>
                    <div>Author Keywords</div>
                    <div style={{ marginTop: "5px", fontWeight: "400" }}>{currentJob.authorKeywords ? currentJob.authorKeywords : "-"}</div>
                </p>
            </div>
        );
    };

    const getKeySDGsData = (keySDGs, sdgDistribution) => {
        const goalIds = keySDGs.split(",").map(sdg => parseInt(sdg.trim()));
        
        return goalIds.map(goalId => {
          const sdgData = sdgDistribution.find(sdg => sdg.goalId === goalId);
          const relevancy = sdgData ? sdgData.relevancy : null;
          return {
            goalId,
            name: sdgNames[goalId - 1],
            relevancy
          };
        });
      };

    const SDGDisplay = () => {
        if (currentJob.keySDGs == null || currentJob.keySDGs === "") {
            return null;
        }
        const keySDGData = getKeySDGsData(currentJob.keySDGs, currentJob.sdgDistribution);

        return (
            <div className="sdg-container">
                {keySDGData.map((sdg) => (
                    <div key={sdg.goalId} className="sdg-box" style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <div style={{ position: "relative", width: "50px", height: "50px", flexShrink: 0 }}>
                            <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg">
                                <rect width="50" height="50" fill={getColorForGoal(sdg.goalId)} />
                            </svg>
                            <span
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    fontSize: "25px",
                                    fontWeight: "bold",
                                    color: "#FFF",
                                    zIndex: 1,
                                }}
                            >
                                {sdg.goalId}
                            </span>
                        </div>
                        <div className="goal-text" style={{ flexGrow: 1 }}>
                            <div className="sdg-relevancy" style={{ fontSize: "16px", fontWeight: "bold" }}>
                                {Math.round(sdg.relevancy * 100)}%
                            </div>
                            <div className="sdg-name" style={{ fontSize: "14px", color: "#555" }}>
                                {sdg.name}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };
    

    const KeySDG = () =>{
        return (
            <div className="container-white">
                <p>Key SDGs</p>
                <SDGDisplay />
            </div>
        );
    };

    const SDGDistributionTable = ({ sdgDistribution }) => {
        const displayedRecords = showAll ? sdgDistribution : sdgDistribution.slice(0, 3);
        return (
            <div className="container-white">
                <p>SDG Distribution</p>
                <div className="sdg-table-wrapper">
                    <table className="sdg-table">
                        <thead>
                            <tr>
                                <th>Goal</th>
                                <th>Relevancy</th>
                                <th style={{ width: "30%" }}>Related Keyword(s)</th>
                                <th>Suggested Keyword(s)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ height: "5px" }}></tr>
                            {displayedRecords.map((item, index) => (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                            <div style={{ position: "relative", width: "35px", height: "35px", flexShrink: 0 }}>
                                                <svg 
                                                    width="35" 
                                                    height="35" 
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <rect width="35" height="35" fill={getColorForGoal(item.goalId)} />
                                                </svg>
                                                <span 
                                                    className="goal-icon-number" 
                                                    style={{
                                                        position: "absolute",
                                                        top: "50%",
                                                        left: "50%",
                                                        transform: "translate(-50%, -50%)",
                                                        fontSize: "18px",
                                                        fontWeight: "bold",
                                                        
                                                        zIndex: 1,
                                                    }}
                                                >
                                                    {item.goalId}
                                                </span>
                                            </div>
                                            <span className="goal-text">{sdgNames[item.goalId - 1]}</span>
                                        </td>
                                        <td>{(item.relevancy * 100).toFixed(2)}%</td>
                                        <td>
                                            {item.relatedKeywords
                                            ? item.relatedKeywords.split(",").map((keyword, i) => (
                                                <span key={i} className="keyword-badge">
                                                    {keyword.trim()}
                                                </span>
                                            )).reduce((acc, curr) => [...acc, curr, <>&nbsp;</>], []).slice(0, -1)
                                            : "-"}
                                        </td>
                                        <td>
                                            {item.suggestedKeywords
                                            ? item.suggestedKeywords.split(",").map((keyword, i) => (
                                                <span key={i} className="keyword-badge">
                                                    {keyword.trim()}
                                                </span>
                                            )).reduce((acc, curr) => [...acc, curr, <>&nbsp;</>], []).slice(0, -1)
                                            : "-"}
                                        </td>
                                    </tr>
                                    {index < displayedRecords.length - 1 && (
                                        <tr>
                                            <td colSpan="4">
                                                <div className="inner">&nbsp;</div>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))}
                            <tr style={{ height: "5px" }}></tr>
                        </tbody>
                    </table>
                </div>
                <div 
                    style={{
                        cursor: "pointer", 
                        fontWeight: "500", 
                        display: "flex", 
                        justifyContent: "center", 
                        alignItems: "center",
                        marginTop: "10px"
                    }} 
                    onClick={() => setShowAll(!showAll)}
                >
                    {showAll ? "Show Less" : "Show All"}
                    {showAll ? <img src={ArrowUpIcon} alt="Arrow Up" style={{ marginLeft: "5px" }} /> : <img src={ArrowDownIcon} alt="Arrow Down" style={{ marginLeft: "5px" }} />}
                </div>
            </div>
        );
    };

    const getColorForGoal = (goalId) => {
        switch(goalId) {
            case 1: return '#E5233D';
            case 2: return '#DDA73A';
            case 3: return '#4CA146';
            case 4: return '#C5192D';
            case 5: return '#EF402C';
            case 6: return '#27BFE6';
            case 7: return '#FBC412';
            case 8: return '#A31C44';
            case 9: return '#F26A2D';
            case 10: return '#E01483';
            case 11: return '#F89D2A';
            case 12: return '#BF8D2C';
            case 13: return '#407F46';
            case 14: return '#1F97D4';
            case 15: return '#59BA48';
            case 16: return '#126A9F';
            case 17: return '#13496B';
            default: return '#000000';
        }
    };

    const SDGDistributionChart = () => {

        const textRender = (args) => {
            args.text = "Goal "+ args.point.x + ": " + (args.point.y * 100).toFixed(2) + "%";
        };
    
        return (
            <div className="container-white">
                <p>Goal Distribution</p>
    
                <div className="chart-legend-container">
                    {/* Chart */}
                    <div className="chart-container">
                        <AccumulationChartComponent 
                            id='charts' 
                            enableSmartLabels={true}
                            width="100%"
                            pointRender={(args) => {
                                args.fill = getColorForGoal(args.point.x);
                            }}
                            textRender={textRender}
                        >
                            <Inject services={[AccumulationDataLabel]} />
                            <AccumulationSeriesCollectionDirective>
                                <AccumulationSeriesDirective
                                    dataSource={currentJob.sdgDistribution}
                                    xName='goalId'
                                    yName='relevancy'
                                    innerRadius='60%'
                                    dataLabel={{
                                        visible: true,
                                        position: "Outside",
                                        font: {
                                            fontFamily: "Inter, sans-serif",
                                        }
                                    }}
                                    animation={{ enable: false }}
                                >
                                </AccumulationSeriesDirective>
                            </AccumulationSeriesCollectionDirective>
                        </AccumulationChartComponent>
                    </div>
    
                    {/* Custom Legend */}
                    <div className="custom-legend">
                        {currentJob.sdgDistribution.map((item, index) => (
                            <div key={index} className="legend-item">
                                <svg 
                                    width="16" 
                                    height="16" 
                                    className="legend-color-box" 
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect width="16" height="16" fill={getColorForGoal(item.goalId)} />
                                </svg>
                                <span className="legend-text">
                                    {`${(item.relevancy * 100).toFixed(2)}%, Goal ${item.goalId}: ${sdgNames[item.goalId-1]}`}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const Footer = () => {
        return (
            <div style={{margin: "0px 32px"}}>
                <hr className="footer"/>
                <div style={{color: "#8F95A0", fontSize: "14px"}}>
                    Any data provided to the system (title, abstract, author keywords) and the analysis results generated by the system will not be shared with third parties. The data provided to the system is owned by the users. We respect your intellectual property rights and do not claim ownership of any content you create while using the system.
                </div>
            </div>
        );
    }
    
    return (
        <>
            {!analysisHistoryClicked && (    
                <div className="panel-container">
                    {jobID > 0 && <HistorySession />}

                    {/* Add New SDG Analysis */}
                    {jobID < 0 && 
                        (<div className="container-wrapper">
                            <div className="main-content">
                                <div style={{ fontSize: "20px", fontWeight: "bold", margin: "40px 200px 15px" }}>
                                    <span onClick={() => handleBackClick()}>
                                        <img src={ArrowPrevIcon} alt="" style={{ cursor: "pointer", marginRight: "5px" }} />
                                    </span>
                                    <span>New Analysis</span>
                                </div>
                                <div className="container-white" style={{margin: "0px 200px", padding: "20px 50px 30px"}}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td><p>Fill in your publication details below and click “Analyze” to start analysis</p></td>
                                            </tr>
                                            <tr>
                                                <td><p>Title</p></td>
                                            </tr>
                                            <tr>
                                                <td><TextBoxComponent
                                                    ref={titleRef}
                                                    placeholder="Input title of the publication" width="100%" height="50px"
                                                    floatLabelType="Never"
                                                    value={title}
                                                    input={(e) => {updateTitle(e.value)}}
                                                    change={(e) => {updateTitle(e.value)}}
                                                /></td>
                                            </tr>
                                            <tr style={{height:"15px"}}></tr>
                                            <tr>
                                                <td><p>Abstract</p></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <TextBoxComponent
                                                        ref={abstractRef}
                                                        placeholder="Input abstract of the publication"
                                                        multiline={true}
                                                        rows={5}
                                                        floatLabelType="Never"
                                                        value={abstract}
                                                        input={(e) => { updateAbstract(e.value); }}
                                                        change={(e) => { updateAbstract(e.value); }}
                                                        cssClass="abstract-textbox"
                                                    />
                                                </td>
                                            </tr>
                                            <tr style={{height:"15px"}}></tr>
                                            <tr>
                                                <td><p>Author Keywords</p></td>
                                            </tr>
                                            <tr>
                                                <td><TextBoxComponent
                                                    ref={authorKeywordsRef}
                                                    placeholder="Input author keywords of the publication" width="100%" height="50px"
                                                    floatLabelType="Never"
                                                    autocomplete={'off'}
                                                    value={authorKeywords}
                                                    input={(e) => {updateAuthorKeywords(e.value)}}
                                                    change={(e) => {updateAuthorKeywords(e.value)}}
                                                /></td>
                                            </tr>
                                            <tr>
                                                <td><div style={{color: "#8F95A0", fontSize: "14px", fontWeight: "400"}}>Use comma(,) as separator</div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="center" style={{paddingTop:"20px"}}>
                                        <button
                                            ref={analyzeRef}
                                            className="btn btn-dark pl-3 pr-3 mr-2 mb-2"
                                            disabled={!title || !abstract}
                                            onClick={() => {
                                                const params = {
                                                    title: title,
                                                    abstract: abstract,
                                                    authorKeywords: authorKeywords,
                                                }
                                                api.post(`analysis/analyze`, params)
                                                .then(async (res) => {
                                                    handleReset();
                                                    showToast("The job is submitted to queue");
                                                }, err => {
                                                    console.log(err.data);
                                                });
                                            }}
                                        ><img src={AnalysisLogo} alt="" />{` `} Analyze</button>
                                        <button className="btn cancel ml-2 mb-2 pl-3 pr-3"
                                            onClick={() => handleReset()}
                                            disabled={!title && !abstract  && !authorKeywords}
                                        >
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                            {
                                <div key="toast">
                                    <ToastComponent 
                                        ref={toast} 
                                        content={toastMessage} 
                                        cssClass="submitted-toast" 
                                        position={{X: 'Center', Y: 'Bottom'}}
                                        timeOut={3000}
                                    />
                                </div>
                            }
                        </div>
                    )}

                    {/* Past SDG Analysis Results*/}
                    {jobID >= 0 && currentJob && (
                        <div className="container-wrapper">
                            <div className="main-content">
                                <div style={{color: "#2B3443", fontSize: "22px", fontWeight: "500",margin: "20px 100px"}}>
                                    Result
                                    <button className="print-button no-print" onClick={() => window.print()}>
                                        <img src={PrinterIcon} alt="" />{` `}Print
                                    </button>
                                </div>
                                <PublicationDetails />
                                <div style={{height:"20px"}}></div>
                                <SDGDistributionTable sdgDistribution={currentJob.sdgDistribution} />
                                <div style={{height:"20px"}}></div>
                                <SDGDistributionChart />
                            </div>
                            <Footer />
                        </div>
                    )}
                </div>
            )}
            {
                analysisHistoryClicked && <AnalysisHistory
                    visibility={analysisHistoryClicked}
                    setVisibility={setAnalysisHistoryClicked}
                    jobID={jobID}
                    setJobID={setJobID}
                    setPrevJobID={setPrevJobID}
                    historyList={historyList}
                    Footer={Footer}
                />
            }
        </>
    );
} 