import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import styles from "./navbar.module.css";
import { Nav, Navbar, Container } from 'react-bootstrap'

import OptixLogo from "../../images/optix-logo.svg"
import AnalysisLogo from "../../images/Mainmenu-analysis.svg";
import JobQueueLogo from "../../images/Mainmenu-job-queue.svg";


import UserManagementLogo from "../../images/Mainmenu-user-management.svg";

import PwdExpiryReminderLogo from "../../images/Mainmenu-pwd-expiry-reminder.svg"
import LogoutLogo from "../../images/Mainmenu-logout.svg"

import { DialogComponent } from '@syncfusion/ej2-react-popups';

import LogoutDialog from "./Dialog/LogoutDialog";

import api from "../../lib/API";
import localStorageService from "../../lib/LocalStorageService";
import moment from 'moment';


const NavBar = (props) => {
  const [logoutVisibility, setLogoutVisibility] = useState(false);
  const [username, setUsername] = useState('');
  const timerIdRef = useRef(null);
  const permission = localStorageService.getPermissions();
  const [showKeyTermsSubmenu, setShowKeyTermsSubmenu] = useState(false);
  const [showIncorrectEndorsementSubmenu, setShowIncorrectEndorsementSubmenu] = useState(false);

  const history = useHistory();


  let location = useLocation();

  let dialogInstance;
  const handleLogout = () => {
    setLogoutVisibility(true);

  }
  // Toggle submenu visibility
  const toggleSubmenu = (menu) => {
    if (menu === "keyTerms") {
      setShowKeyTermsSubmenu(!showKeyTermsSubmenu);
      setShowIncorrectEndorsementSubmenu(false); // Ensure only one submenu is open at a time

    } else if (menu === "incorrectEndorsement") {
      setShowIncorrectEndorsementSubmenu(!showIncorrectEndorsementSubmenu);
      setShowKeyTermsSubmenu(false); // Ensure only one submenu is open at a time
    }
  };

  // Handling Sub menu item
  // Adjusted click handler to use event parameter
  const handleKeyTermClick = (itemPath) => {
    history.push(itemPath);
  };

  useEffect(() => {
    const checkLastActivity = () => {
      if (moment().diff(api.getLastActiveDatetime(), 'seconds') > (process.env.REACT_APP_IDLE_TIMEOUT || 1800)) {
        localStorageService.clearToken();
        window.location.href = '/login';
      }
    };
    let mounted = true;
    timerIdRef.current = setInterval(checkLastActivity, 5000);
    return () => mounted = false;
  }, [])


  // Define submenu items for Key Terms 
  const keyTermsSubmenuItems = [
    { name: " AI Results", path: "/key-terms/ai-result" },
    { name: " Feedback List", path: "/key-terms/feedback-list" }
  ];

  const incorrectEndorsementSubmenuItems = [
    { name: " AI Results", path: "/incorrect-endorsement/ai-result" },
    { name: " Feedback List", path: "/incorrect-endorsement/feedback-list" }
  ];

  // SubMenuContainer component 
  const SubMenuContainer = ({ items, onSelect, className, itemClassName }) => {
    return (
      <div className={`submenu_container ${className}`}>
        {items.map((item, index) => (
          <div key={index} className={`submenu_item ${itemClassName}`} onClick={() => {
            setShowKeyTermsSubmenu(false);
            setShowIncorrectEndorsementSubmenu(false);
            onSelect(item.path); // Pass item.path directly
          }} data-itemclassname={item.className}>
            {item.name}
          </div>
        ))}
      </div>
    );
  };
  return (
    <React.Fragment>
      <div className="navContainer" style={{position: "sticky", top: 0, zIndex: 999}}>
        <nav className="navbar navbar-expand-sm navbar-light bg-dark pb-0 pt-0" style={{paddingLeft: "32px"}}>
          {permission?.search("READ_JOB") !== -1 ? (
            <Link
              className={`navbar-brand text-white ${styles.menu_item}`}
              style={{marginRight: "5px"}}
              to="/" role="heading"
              aria-level="1"
              onClick={e => {
                if (location.pathname === "/")
                  e.preventDefault();
              }}>
              <img src={OptixLogo} alt="" style={{ paddingBottom: "0.4em" }} />
            </Link>
          ) : (
            <Link
              className={`navbar-brand text-white ${styles.menu_item}`}
              style={{marginRight: "10px"}}
              to="/user-management" role="heading"
              aria-level="1"
              onClick={e => {
                if (location.pathname === "/user-management")
                  e.preventDefault();
              }}>
              <img src={OptixLogo} alt="" style={{ paddingBottom: "0.4em" }} />
            </Link>
          )}

          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav mr-auto">
              
              <li className="nav-item"
                style={{ display: permission?.search("READ_JOB") === -1 && "none", backgroundColor: location.pathname === "/" ? "#505465" : "#343a40",
                    paddingLeft: "2px",
                    paddingRight: "2px",
                    paddingTop: "8px",
                    paddingBottom: "8px"
                 }}>
                <Link
                  className={`nav-item nav-link text-white p-2 ${styles.menu_item}`}
                  to="/"
                  onClick={e => {
                    if (location.pathname === "/")
                      e.preventDefault();
                    setShowKeyTermsSubmenu(false);
                    setShowIncorrectEndorsementSubmenu(false);
                  }}>
                  <img src={AnalysisLogo} alt="" className="navbar-nav-svg mr-1" />Analysis
                </Link>
              </li>

              <hr style={{ display: (permission?.search("READ_JOB") === -1) && "none" }} />

              <li className="nav-item active"
                style={{ display: permission?.search("READ_JOB") === -1 && "none", backgroundColor: location.pathname === "/job-queue" ? "#505465" : "#343a40",
                    paddingLeft: "2px",
                    paddingRight: "2px",
                    paddingTop: "8px",
                    paddingBottom: "8px"
                 }}>
                <Link
                  className={`nav-item nav-link text-white active p-2 ${styles.menu_item}`}
                  to="/job-queue"
                  onClick={e => {
                    if (location.pathname === "/job-queue")
                      e.preventDefault();
                    setShowKeyTermsSubmenu(false);
                    setShowIncorrectEndorsementSubmenu(false);
                  }}>
                  <img src={JobQueueLogo} alt="" className="navbar-nav-svg mr-1" />Job Queue
                </Link>
              </li>

              <hr style={{ display: (permission?.search("READ_JOB") === -1 || permission?.search("USER_MANAGEMENT") === -1) && "none" }} />

              <li className="nav-item"
                style={{ display: permission?.search("USER_MANAGEMENT") === -1 && "none", backgroundColor: location.pathname === "/user-management" ? "#505465" : "#343a40",
                    paddingLeft: "2px",
                    paddingRight: "2px",
                    paddingTop: "8px",
                    paddingBottom: "8px"
                 }}>
                <Link
                  className={`nav-item nav-link text-white p-2 ${styles.menu_item}`}
                  to="/user-management"
                  onClick={e => {
                    if (location.pathname === "/user-management")
                      e.preventDefault();
                    setShowKeyTermsSubmenu(false);
                    setShowIncorrectEndorsementSubmenu(false);
                  }}>
                  <img src={UserManagementLogo} alt="" className="navbar-nav-svg mr-1" />User Management
                </Link>
              </li>
            </ul>


            <ul className="navbar-nav ml-auto">
              <li className="nav-item nav-link text-white"
                style={{ backgroundColor: location.pathname === "/profile" ? "#505465" : "#343a40",
                    paddingLeft: "2px",
                    paddingRight: "2px",
                    paddingTop: "8px",
                    paddingBottom: "8px"
                 }} >
                <Link
                  id="username"
                  className={`nav-item nav-link text-white p-2 ${styles.menu_item}`}
                  to="/profile"
                  onClick={e => {
                    if (location.pathname === "/profile")
                      e.preventDefault();
                    setShowKeyTermsSubmenu(false);
                  }}>
                  {props.pwdExpiryReminderVisibility && <img src={PwdExpiryReminderLogo} alt="" title="Password will be expired within 30 days." className="navbar-nav-svg mr-1" />}
                  {localStorageService.getUsername() ? localStorageService.getUsername().toLowerCase() : ''}
                </Link>
              </li>

              <hr />

              <li className="nav-item nav-link p-2" onClick={handleLogout}>
                {/* <Link className="nav-item nav-link text-white p-2" to="/Logout">
              Logout <img src={LogoutLogo} alt="" className="navbar-nav-svg ml-2" />
              </Link> */}

                <span style={{ cursor: "pointer" }} className={`nav-item nav-link text-white p-2 ${styles.menu_item}`}>Logout <img src={LogoutLogo} alt="" className="navbar-nav-svg ml-2" /></span>


              </li>

            </ul>
          </div>
        </nav>

      </div>
      {showKeyTermsSubmenu && (
        <>
          <SubMenuContainer
            className={styles.submenu_container}
            itemClassName={styles.submenu_item}
            items={keyTermsSubmenuItems}
            onSelect={(itemPath) => handleKeyTermClick(itemPath)} 
            onCollapse={() => setShowKeyTermsSubmenu(false)}
            pathname={location.pathname}
          />
        </>
      )}

      {showIncorrectEndorsementSubmenu && (
              <>
                <SubMenuContainer
                  className={styles.submenu_container}
                  itemClassName={styles.submenu_item}
                  items={incorrectEndorsementSubmenuItems}
                  onSelect={(itemPath) => handleKeyTermClick(itemPath)} 
                  onCollapse={() => setShowIncorrectEndorsementSubmenu(false)}
                  pathname={location.pathname}
                />
              </>
            )}

      <LogoutDialog
        visibility={logoutVisibility}
        setVisibility={setLogoutVisibility} />

    </React.Fragment>
  );
}
export default NavBar;
