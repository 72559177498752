import './App.css';
import React,{useEffect, useState} from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";



import Login from './components/Login/Login';
import ForgotPassword from './components/Login/ForgotPassword';
import ResetPassword from './components/Login/ResetPassword';

import Analysis from './components/Analysis/Analysis';
import JobQueue from './components/JobQueue/JobQueue';
import UserManagement from './components/UserManagement/UserManagement';
import Profile from './components/UserProfile/Profile';
import localStorageService from './lib/LocalStorageService'
import api from "./lib/API";
import PrivateRoute from './PrivateRoute';

function App() {
  const location = useLocation();
  const [pwdExpiryReminderVisibility, setPwdExpiryReminderVisibility] = useState(localStorageService.getPwdExpiryReminder() === "true");
  localStorageService.setPwdExpiryReminderVisibilityCallback(setPwdExpiryReminderVisibility);
  return (
    <React.Fragment>
        {!["/login", "/forgot-password"].includes(location.pathname) &&
          !location.pathname.startsWith("/reset-password") &&
          (<NavBar pwdExpiryReminderVisibility={pwdExpiryReminderVisibility} />)
        }
        <Switch>
          <Route path = "/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password/:token" component={ResetPassword} />

          <PrivateRoute exact path="/" component={Analysis} />
          <PrivateRoute path="/job-queue" component={JobQueue} />
          <PrivateRoute path="/user-management" component={UserManagement} />
          <PrivateRoute path="/profile" component={Profile} />

        </Switch>
      </React.Fragment>
  );
}

export default App;
