import React, {useState, useEffect, useRef} from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import api from "../../../lib/API";

export default function CreateUserDialog(props){
    // let dialogInstance;
    const [rawData, setRawData] = useState();
    const [roles,setRoles] = useState();

    const [username, setUsername] = useState('');
    const [name, setName] = useState('');
    const [department, setDepartment] = useState('');
    const [email, setEmail] = useState('');
    const [selectedItem, setSelectedItem] = useState(props.rolesList.defaultRoleId);

    const [errorMessage, setErrorMessage] = useState('');
    const [isConfirmed, setIsConfirmed] = useState(false);

    const dropdownsRef = useRef(null);
    const confirmButtonRef = useRef(null);
    const usernameRef = useRef(null);
    const nameRef = useRef(null);
    const departmentRef = useRef(null);
    const emailRef = useRef(null);

    useEffect(()=>{
        let mounted = true;
        //console.log(confirmButtonRef);
        //upload Button ref
        if(mounted && props.visibility)
            if(!isConfirmed){
                confirmButtonRef.current.removeAttribute("disabled");
                confirmButtonRef.current.innerHTML = `<span>Confirm</span>`;
            }
            else{
                confirmButtonRef.current.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="ml-2">Confirm</span>`;
                confirmButtonRef.current.setAttribute("disabled","disabled");
            }
        return () => mounted = false;
    },[isConfirmed])

    useEffect(()=>{
        let mounted = true;
        if(mounted && props.visibility){
            if(usernameRef.current.cssClass === "e-error")
                usernameRef.current.cssClass = '';
        }
        return () => mounted = false;
    },[username]);

    useEffect(()=>{
        let mounted = true;
        if(mounted && props.visibility){
            if(dropdownsRef.current.cssClass === "e-error")
                dropdownsRef.current.cssClass = '';
        }
        return () => mounted = false;
    },[selectedItem])

    useEffect(()=>{
        let mounted = true;
        if(mounted && props.visibility){
            if(nameRef.current.cssClass === "e-error")
                nameRef.current.cssClass = '';
        }
        return () => mounted = false;
    },[name])

    useEffect(()=>{
        let mounted = true;
        if(mounted && props.visibility){
            if(departmentRef.current.cssClass === "e-error")
                departmentRef.current.cssClass = '';
        }
        return () => mounted = false;
    },[department])

    useEffect(()=>{
        let mounted = true;
        if(mounted && props.visibility){
            if(emailRef.current.cssClass === "e-error")
                emailRef.current.cssClass = '';
        }
        return () => mounted = false;
    },[email])

    const footerTemplate = () => {
        return (
            <div className="row">
                <div className="col-7">
                    {errorMessage && <span style={{textAlign:"left !important"}} className="float-left mt-2 text-left text-danger m-0">{errorMessage}</span>}
                </div>
                <div className="col-5">
                    <button className="btn cancel mr-2 mb-2 pl-4 pr-4" 
                    onClick={()=>{
                        if (dropdownsRef.current) {
                            dropdownsRef.current.clear();
                        }
                        props.setVisibility(false);
                    }}>Cancel</button>
                    <button className="btn btn-dark pl-4 pr-4 mr-2 mb-2"
                    ref={confirmButtonRef}
                    disabled={!( username && name && selectedItem && department && email )}
                    onClick={()=>{
                        setIsConfirmed(true);
                        const params = {
                            // roleId:rawData[rawData.findIndex(obj => {return obj.roleName === selectedItem})].roleId,
                            roleId: selectedItem,
                            username: username,
                            displayName: name,
                            department: department,
                            email: email,
                        }
                        api.post('/users', params).then(async (res) => {
                            console.log(res.data);
                            props.fetchUsers();
                            handleClick();
                        }, err => {
                            console.log(err);
                            setErrorMessage(err.response.data.message);
                            setIsConfirmed(false);
                            if(err.response.data.errorFieldList.length !== 0)
                                err.response.data.errorFieldList.forEach(element => {
                                    switch (element) {
                                        case "username":
                                            usernameRef.current.cssClass = "e-error";
                                            break;
                                        case "role":
                                            dropdownsRef.current.cssClass = "e-error";
                                            break;
                                        case "name":
                                            nameRef.current.cssClass = "e-error";
                                            break;
                                        case "department":
                                            departmentRef.current.cssClass = "e-error";
                                            break;
                                        case "email":
                                            emailRef.current.cssClass = "e-error";
                                            break;
                                        default:
                                            break;
                                    }
                                });
                        })
                    }}>Confirm</button>
                </div>
            </div>
        );

    };
     // function to handle the close on overlay
     const handleClick = () => {
        setIsConfirmed(false);
        props.setVisibility(false);
        setUsername('');
        setName('');
        setDepartment('');
        setEmail('');
        setSelectedItem(props.rolesList.defaultRoleId);
        setErrorMessage('');
        usernameRef.current.cssClass = '';
        dropdownsRef.current.cssClass = '';
        nameRef.current.cssClass = '';
        departmentRef.current.cssClass = '';
        emailRef.current.cssClass = '';
        dropdownsRef.current.index = props.rolesList.roleList.findIndex(e=>{return e.roleId === props.rolesList.defaultRoleId});
        if (dropdownsRef.current) {
            dropdownsRef.current.clear();
        }
        // dropdownsRef.current.clear();
        // if (args.checked) {
        //     this.dialogInstance.overlayClick = () => {
        //         this.setState({ hideDialog: false });
        //     };
        // }
        // else {
        //     this.dialogInstance.overlayClick = () => {
        //         this.setState({ hideDialog: true });
        //     };
        // }
    }
    return (
        <div>
            <div className="App" id='dialog-target'>
                <DialogComponent 
                width="40%"
                close={handleClick} 
                header='Create User' 
                visible={props.visibility} 
                showCloseIcon={false} 
                footerTemplate={footerTemplate}
                isModal={true}
                beforeOpen={()=>setSelectedItem(props.rolesList.defaultRoleId)}
                // ref={dialog => dialogInstance = dialog} 
                overlayClick = {handleClick}>
                <span className="ml-2">Please fill in below information to create new user</span>
                    <div className="textboxes mt-4">
                        <div className="row">
                            <div className="col">
                                <h6>Username</h6>
                                <TextBoxComponent ref={usernameRef} value={username} input={e=>setUsername(e.value)} change={e=>setUsername(e.value)} placeholder="Username" floatLabelType="Never" autocomplete={'username'}/>
                            </div>
                            <div className="col">
                                <h6>Name</h6>
                                <TextBoxComponent ref={nameRef} value={name} input={e => setName(e.value)} change={e => setName(e.value)} placeholder="Name" floatLabelType="Never" autocomplete={'off'} />
                            </div>
                        </div>
                    </div>
                    <React.Fragment>
                        <div className="textboxes">
                            <div className="row">
                                <div className="col">
                                    <h6>Department</h6>
                                    <TextBoxComponent ref={departmentRef} value={department} input={e => setDepartment(e.value)} change={e => setDepartment(e.value)} placeholder="Department" floatLabelType="Never" autocomplete={'off'} />
                                </div>
                                <div className="col">
                                    <h6>Email</h6>
                                    <TextBoxComponent ref={emailRef} value={email} input={e => setEmail(e.value)} change={e => setEmail(e.value)} placeholder="Email" floatLabelType="Never" autocomplete={'off'} />
                                </div>
                            </div>
                        </div>
                        <div className="textboxes">
                            <div className="row">
                                <div className="col">
                                    <h6>Role</h6>
                                    <DropDownListComponent 
                                    ref={dropdownsRef}
                                    fields={{text:"roleName", value:"roleId"}}  
                                    dataSource={props.rolesList?props.rolesList.roleList:null}
                                    index={props.rolesList?props.rolesList.roleList.findIndex(e=>{return e.roleId === props.rolesList.defaultRoleId}):null}
                                    select={(e)=>{
                                        if(props.visibility)
                                        setSelectedItem(e.itemData.roleId)}}
                                    change={(e)=>{
                                        if(props.visibility)
                                        setSelectedItem(e.itemData.roleId)}} />
                                </div>
                                <div className="col">
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                <span className="ml-2">* All fields are mandatory.</span>
                </DialogComponent>
            </div>
        </div>
    );

};