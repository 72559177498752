import React, { useState } from "react"; 
import {ProgressButtonComponent} from "@syncfusion/ej2-react-splitbuttons"; 
import OptixLogo from "../../images/Optix-logo-black.svg"
import "./forgotPassword.css";
import api from '../../lib/API';

export default function ForgotPassword() {
    const [username, setUsername] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [requestSent, setRequestSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    let buttonRef = null;

    const onKeyDown = (e) => {
        if (e.keyCode === 13 && username) {
        onRequestPasswordReset();
        e.preventDefault();
        }
    };

    const onChangeData = (value) => {
        setUsername(value);
        setErrorMessage('');
    };

    const onProgress = (args) => {
        if (!isLoading) {
        args.percent = 100;
        }
    };

    const onRequestPasswordReset = () => {
        setIsLoading(true);
        buttonRef.start();
        const params = {
            recipient: username,
        };

        api.post('reset_password', params).then(
        () => {
            setIsLoading(false);
            setRequestSent(true);
        },
        () => {
            setIsLoading(false);
            setRequestSent(true);
            setErrorMessage('Request failed, try again later.');
        }
        );
    };

    return (
        <div className="forgot-back">
            <div className="forgot-container align-center">
                <div className="row h-100 justify-content-center align-items-center">
                    <div>
                        <div className="forgot-pwd-logo mb-4">
                            <img src={OptixLogo} alt="Optix Logo" />
                            <span className="forgot-login-title mt-4"></span>
                        </div>
                        {!requestSent ? (
                        <div className="forgot-container-white">
                            <span className="forgot-login-title mt-1 mb-3">SDG Classification and Keyword Recommendation</span>
                            <div className="mt-2 mb-3">
                            <input
                                type="text"
                                className="forgot-loginform-input mt-4"
                                id="username"
                                placeholder="Username"
                                value={username}
                                onKeyDown={onKeyDown}
                                onChange={(e) => onChangeData(e.target.value)}
                            />
                            </div>
                            {errorMessage ? (
                            <div className="text-danger">
                                <span>{errorMessage.replace('.', '')}</span>
                            </div>
                            ) : (
                            <div><span className="text-danger" style={{ visibility: "hidden" }}>error message</span></div>
                            )}
                            <div>
                            <ProgressButtonComponent
                                cssClass="btn btn-dark p-1 pl-3 pr-3"
                                ref={(element) => { buttonRef = element; }}
                                content="Next"
                                disabled={!username}
                                style={{ opacity: username ? 1 : 0.5 }}
                                onClick={onRequestPasswordReset}
                                progress={onProgress}
                            />
                            </div>
                        </div>
                        ) : (
                        <div className="forgot-container-white-reset">
                            <span className="forgot-login-title mt-5">Reset Password</span>
                            <div className="mt-4 ml-4 mr-4" style={{ textAlign: "center" }}>
                            <span className="forgot-login-text" style={{ fontSize: "16px" }}>
                                A password reset email has been sent to your registered email address. Please check your email account.
                            </span>
                            </div>
                        </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
