import React, { useState, useEffect, useRef} from "react";
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import HidePasswordIcon from "../../images/HidePassword-icon.svg";
import ShowPasswordIcon from "../../images/ShowPassword-icon.svg";
import "./style.css";
import api from "../../lib/API";
import localStorageService from "../../lib/LocalStorageService";

export default function Profile (){

    const [username, setUsername] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [role, setRole] = useState('');
    const [department, setDepartment] = useState('');
    const [email, setEmail] = useState('');

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [toastMessage, setToastMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const oldPasswordRef = useRef(null);
    const newPasswordRef = useRef(null);
    const confirmPasswordRef = useRef(null);
    const toast = useRef(null);

    useEffect(()=>{
        api.get('/profile').then(async (res) => {
          setUsername(res.data.username);
          setDisplayName(res.data.displayName);
          setRole(res.data.roleName);
          setDepartment(res.data.department)
          setEmail(res.data.email);
      }, err => {
          console.log(err.data);
      })
      },[])

    useEffect(()=>{
        let mounted = true;
        if(mounted){
            if(newPassword || confirmPassword){
                if(newPassword!==confirmPassword){
                    newPasswordRef.current.cssClass="e-error";
                    confirmPasswordRef.current.cssClass="e-error";
                    setErrorMessage("Password does not match");
                }
                else{
                    newPasswordRef.current.cssClass="";
                    confirmPasswordRef.current.cssClass="";
                    setErrorMessage("");
                }
            }
            else{
                newPasswordRef.current.cssClass="";
                confirmPasswordRef.current.cssClass="";
                setErrorMessage("");
            }

        }
        return () => mounted = false;
    },[newPassword, confirmPassword])

    useEffect(()=>{
        let mounted = true;
        if(mounted){
            if(oldPasswordRef.current.cssClass === "e-error"){
                oldPasswordRef.current.cssClass = '';
            }
        }
        return () => mounted = false;
    },[currentPassword])

    const showToast = (message) => {
        setToastMessage(message);
        toast.current.show();
    };

    const handleDone = () => {
        newPasswordRef.current.cssClass="";
        confirmPasswordRef.current.cssClass="";
        oldPasswordRef.current.cssClass = '';
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setErrorMessage('');
    }

    const changePassword = () => {
        api.post('/profile/password',{newPassword:confirmPassword, currentPassword:currentPassword}).then(res=>{
            console.log(res.data);
            localStorageService.setPwdExpiryReminder("false");
            handleDone();
            showToast("Password has been changed successfully");
        }, err => {
            console.log(err);
            setErrorMessage(err.response.data.message);
            if(err.response.data.errorFieldList.length !== 0)
                err.response.data.errorFieldList.forEach(element => {
                    switch (element) {
                        case "old_password":
                            oldPasswordRef.current.cssClass = "e-error";
                            break;
                        case "password":
                            newPasswordRef.current.cssClass = "e-error";
                            break;
                        default:
                            break;
                    }
                });
        })
        // setErrorMessage("testing");
    }

    return (
        <div className="content">
            <h4 className="page-title mb-4" style={{marginTop:"10px"}}>User Profile</h4>
            <div className="info" style={{backgroundColor:"#FFF"}}>
                <div>
                    <table className="table table-sm table-borderless" style={{width:"30%"}}>
                        <tbody>
                            <tr>
                                <td className="font-weight-bold">Username</td>
                                <td>{username}</td>
                            </tr>
                            <tr>
                                <td className="font-weight-bold">Name</td>
                                <td>{displayName}</td>
                            </tr>
                            <tr>
                                <td className="font-weight-bold">Role</td>
                                <td>{role}</td>
                            </tr>
                            <tr>
                                <td className="font-weight-bold">Department</td>
                                <td>{department}</td>
                            </tr>
                            <tr>
                                <td className="font-weight-bold">Email</td>
                                <td>{email}</td>
                            </tr>
                        </tbody>
                    </table>
                    <hr className="greyline"/>
                    <div style={{padding: "5px", margin: "20px 0px", fontSize:"20px", fontWeight: "500"}}>
                        Change Password
                    </div>
                    <table className="table table-sm table-borderless" style={{width:"30%"}}>
                        <tbody>
                            <tr>
                                <td className="font-weight-bold" style={{verticalAlign: "middle"}}>Current Password</td>
                                <td>
                                    <div style={{ position: "relative" }}>
                                        <TextBoxComponent 
                                            ref={oldPasswordRef} 
                                            type={showCurrentPassword ? "text" : "password"} 
                                            value={currentPassword} 
                                            input={e => setCurrentPassword(e.value)} 
                                            placeholder="Current Password" 
                                            floatLabelType="Never" 
                                        />
                                        <span 
                                            onClick={() => setShowCurrentPassword(!showCurrentPassword)} 
                                            style={{
                                                position: "absolute",
                                                right: "10px",
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                                cursor: "pointer",
                                                zIndex: 1
                                            }}
                                        >
                                            {showCurrentPassword ? <img src={ShowPasswordIcon} alt="Show Password"/> : <img src={HidePasswordIcon} alt="Hide Password"/>}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="font-weight-bold" style={{verticalAlign: "middle"}}>New Password</td>
                                <td>
                                    <div style={{ position: "relative" }}>
                                        <TextBoxComponent 
                                            ref={newPasswordRef} 
                                            type={showNewPassword ? "text" : "password"} 
                                            value={newPassword} 
                                            input={e => setNewPassword(e.value)} 
                                            placeholder="New Password" 
                                            floatLabelType="Never" 
                                        />
                                        <span 
                                            onClick={() => setShowNewPassword(!showNewPassword)} 
                                            style={{
                                                position: "absolute",
                                                right: "10px",
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                                cursor: "pointer",
                                                zIndex: 1
                                            }}
                                        >
                                            {showNewPassword ? <img src={ShowPasswordIcon} alt="Show Password"/> : <img src={HidePasswordIcon} alt="Hide Password"/>}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="font-weight-bold" style={{verticalAlign: "middle"}}>Re-type New Password</td>
                                <td>
                                    <div style={{ position: "relative" }}>
                                        <TextBoxComponent 
                                            ref={confirmPasswordRef} 
                                            type={showConfirmPassword ? "text" : "password"} 
                                            value={confirmPassword} 
                                            input={e => setConfirmPassword(e.value)} 
                                            placeholder="Confirm New Password" 
                                            floatLabelType="Never" 
                                        />
                                        <span 
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)} 
                                            style={{
                                                position: "absolute",
                                                right: "10px",
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                                cursor: "pointer",
                                                zIndex: 1
                                            }}
                                        >
                                            {showConfirmPassword ? <img src={ShowPasswordIcon} alt="Show Password"/> : <img src={HidePasswordIcon} alt="Hide Password"/>}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{padding: "5px", marginTop: "30px", fontSize:"15px"}}>
                        <div><b>Passwords must:</b></div>
                        <div>Be at minimum of 8 characters</div>
                        <div>Include at least one lowercase letter (a-z)</div>
                        <div>Include at least one uppercase letter (A-Z)</div>
                        <div>Include at least one number (0-9)</div>
                        <div>Include at least one special character</div>
                    </div>
                    <div style={{padding: "5px"}}>
                        <p className="text-danger" style={{ minHeight: "1em" }}>
                            {errorMessage || <span>&nbsp;</span>}
                        </p>
                        <button
                            className="btn btn-dark pl-3 pr-3"
                            onClick={changePassword}
                            disabled={!(currentPassword && newPassword && confirmPassword && (newPassword === confirmPassword))}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
            {
                <div key="toast">
                    <ToastComponent 
                        ref={toast} 
                        content={toastMessage} 
                        cssClass="success-password-toast" 
                        position={{X: 'Center', Y: 'Bottom'}}
                        timeOut={3000}
                    />
                </div>
            }
        </div>
    );

};